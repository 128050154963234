.table-element {
  display: flex;
  align-items: center;
}

.user-table-element:nth-of-type(odd) {
  background-color: #fff;
}

.user-table-element:nth-of-type(odd):hover {
  background-color: #f9fafb;
}

.user-table-element:nth-of-type(even) {
  background-color: #f9fafb;
}

.user-table-element:nth-of-type(even):hover {
  background-color: #fff;
}

.table-name {
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
}

.table-name img {
  margin-right: 0.75rem;
}

.table-name span {
  font-weight: 450;
  font-size: 0.925rem;
  line-height: 1rem;
  color: #202027;
}

.table-user input {
  margin-right: 0.75rem;
}

.table-tags {
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
}

.table-tags .tag {
  margin-right: 0.25rem;
}

.dashboard-table-progress {
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  width: 100%;
}

.user-table-edit {
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-table-edit i {
  width: 1.25rem;
  height: 1.25rem;
}

.progress-line {
  background-color: #eff8ff;
  border-radius: 0.25rem;
  height: 0.5rem;
  width: 100%;
  margin-right: 0.75rem;
}

.progress-percent {
  background-color: #2b4ff1;
  border-radius: 0.25rem;
  height: inherit;
}

.card-container {
  background-color: var(--white);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  height: 100%;
  border: 1px solid #dddddd;
}

.card-text {
  padding: 1rem 1rem;
  height: 65px;
}

.card-title {
  display: flex;
  align-items: center;
}

.card-title .title {
  font-size: 1.25rem;
  line-height: 1.5625rem;
  weight: 700;
  color: #202027;
}

.card-description {
  font-size: 0.925rem;
  line-height: 1rem;
  weight: 450;
  color: #757e8c;
}

.user-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.user-card-title {
  font-size: 1.25rem;
  line-height: 1.5625rem;
  weight: 700;
  color: #202027;
}

.card-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
}

.small-card {
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border: 1px solid #dddddd;
  border-radius: 0.5rem;
  padding: 1rem;
}

.small-card:not(:last-of-type) {
  margin-right: 1rem;
}

.card-image {
  border-radius: 0.625rem;
  width: 3rem;
  height: 3rem;
  color: #ffffff;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-image i {
  font-size: 1rem;
}

.card-title {
  font-weight: 450;
  font-size: 0.925rem;
  line-height: 1rem;
  color: #757e8c;
  margin-bottom: 0rem;
}

.card-amount {
  font-weight: bold;
  font-size: 2.125rem;
  line-height: 2.6875rem;
  letter-spacing: -0.02em;
  color: #202027;
}

.check {
  height: 1.25rem;
  width: 1.25rem;
}

input[type="checkbox"] {
  margin-right: 0.75rem;
}

.search {
  display: flex;
  align-items: center;
  color: #757e8c;
}

.search-container {
  margin-right: 1rem;
  width: 50%;
}

.delete-container {
  margin-right: 1rem;
  /* width: 35%; */
  padding-top: 25px;
}

.search-icon {
  background: transparent;
  border: none;
  outline: none;
  margin-right: -33px;
  font-size: 0.925rem;
  z-index: 1;
}

.search-field {
  width: 100%;
  padding: 10px 15px 10px 36px;
  border: 1px solid #dddddd;
  border-radius: 0.5rem;
  outline: none;
}

.text-field {
  width: 100%;
  padding: 10px 15px 10px 10px;
  border: 1px solid #dddddd;
  border-radius: 0.5rem;
  outline: none;
}

.sort-container {
  flex-grow: 1;
}

select.sort-select {
  padding: 10px 15px 10px 15px;
  border: 1px solid #dddddd;
  border-radius: 0.5rem;
  color: #757e8c;
  width: 100%;
}

.search-label,
.sort-label {
  padding-bottom: 0.375rem;
}

.search-label {
  margin-left: -0.625rem;
}

.dashboard-name-width {
  width: 30%;
}

.dashboard-progress-width {
  width: 38%;
}

.dashboard-upDown-width {
  width: 8%;
}

.dashboard-tags-width {
  width: 24%;
}

.user-name-width {
  width: 30%;
}

.user-status-width {
  width: 10%;
}

.user-email-width {
  width: 22%;
}

.user-tags-width {
  width: 32%;
}

.user-edit-width {
  width: 6%;
}

/* //Select */

.dropdown:hover,
.dropdown:target,
.dropdown--active {
  border-color: #dddddd;
}
.dropdown--active {
  border-radius: 8px 8px 0 0;
}
.dropdown--selected {
  color: black;
}
.dropdownHeader {
  padding: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
}

.dropdownHeader-title i {
  margin-right: 14px;
}

.dropdown_items {
  width: inherit;
  padding: 4px 0px;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  border: 1px solid #dddddd;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  position: absolute;
  top: 44px;
  left: 0;
  z-index: 2;
  max-height: 174px;
  overflow: auto;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 0px;
}

.dropdownItem {
  padding-top: 17px;
  padding-right: 15px;
  padding-bottom: 15px;
  display: flex;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dropdownItem:hover,
.dropdownItem--active {
  background-color: #f1f5f8;
  font-weight: bold;
}
@media screen and (max-width: 1440px) {
  .small-card {
    padding: 1rem;
  }
  .content-wrapper {
    margin: 3.75rem auto;
    width: 86%;
  }

  .dashboard-name-width {
    width: 20%;
  }

  .dashboard-upDown-width {
    width: 6%;
  }

  .dashboard-tags-width {
    width: 34%;
  }

  .user-name-width {
    width: 26%;
  }

  .user-status-width {
    width: 10%;
  }

  .user-email-width {
    width: 28%;
  }

  .user-tags-width {
    width: 20%;
  }

  .user-edit-width {
    width: 6%;
  }

  .table-tags {
    flex-direction: column;
  }

  .table-tags .tag {
    margin-right: 0;
    margin-bottom: 0.25rem;
  }
}

.cp {
  cursor: pointer;
}

.iconBig {
  font-size: 25px;
}

.infoBlock {
  border-radius: 10px;
  background: #eff8ff;
  border: 2px solid #2b4ff1;
  padding: 20px;
  width: 50%;
  color: #000;
}

.blue {
  color: #2b4ff1;
}

.red {
  color: red;
}

.link:hover {
  text-decoration: underline;
}

.border-rounded {
  border-radius: 10px;
  border: 1px solid #dddddd;

  padding: 10px;
}

.border-label {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 5px;
  display: inline-block;
  margin-top: 3px;
  margin-bottom: 3px;
  width: 100%;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-20 {
  width: 20% !important;
}

.btnYes {
  background: #ecfdf3;
  border: 1px solid #027a48;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: #027a48;
  border-radius: 8px;
}

.btnNo {
  background: #fef3f2;
  border: 1px solid #d92d20;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: #d92d20;
  border-radius: 8px;
}

.centerXY {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-menu {
  margin-top: 130px;
}

.user-management-nav .dropdown-menu {
  margin-left: -25px;
}

.dashboard-weekly_progress .dropdown-menu {
  margin-top: 90px;
}

.dashboard-table .dropdown-menu {
  margin-top: 125px;
}

.user-management-table .dropdown-menu {
  margin-top: 37px;
  margin-left: -140px;
}

.modal-header {
  display: none;
}

.modal-footer {
  padding: 0;
}

.modal-body {
  padding: 0.6rem;
}

.iconPlus {
  color: #0d6efd;
  font-size: 20px;
}

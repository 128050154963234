.user-edit-wrapper {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 37px auto 17px;
}
.user-edit-back-button {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.02em;
  color: #202027;
  margin-bottom: 25px;
}

.user-edit-back-button i {
  margin-right: 4px;
}
.user-edit-container {
  width: 100%;
  background: white;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px;
}

.user-edit-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.user-edit-header-avatar {
  width: 160px;
  height: 160px;
  border-radius: 100%;
  margin-right: 24px;
}

.user-edit-header-title {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  color: #202027;
}

.user-edit-item {
  display: flex;
  align-items: flex-start;
  margin: 10px 0;
}

.user-edit-item-title {
  width: 30%;
  margin-right: 32px;
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  line-height: 16px;
  color: #202027;
  display: flex;
  align-self: center;
}

.user-edit-item-input-block,
.user-edit-item-buttons,
.user-edit-item-tags {
  width: 56%;
}

.user-edit-item-input-email i {
  position: absolute;
  padding: 12px 14px;
  min-width: 40px;
}

.user-edit-item-input-email input {
  padding-left: 42px;
}

.user-edit-item-inputs {
  display: grid;
  grid-template-columns: auto auto;
  gap: 24px;
}

.user-edit-item-tags {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.user-edit-item-tags-items {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
}

.user-edit-item-tags-items .tag {
  margin-right: 10px;
  margin-bottom: 4px;
}

.user-edit-item-input {
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 100%;
  padding: 12px 14px;
  color: #202027;
}

.user-edit-item-btn-black,
.user-edit-item-btn-warn {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: fit-content;
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  line-height: 16px;
  border: none;
  padding: 10px 16px;
  text-transform: capitalize;
}

.user-edit-item-btn-black {
  background: #f1f5f8;
  color: #202027;
  margin-right: 10px;
}

.user-edit-item-btn-warn {
  background: #fef3f2;
  color: #d92d20;
}

.user-edit-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
}

.user-edit-btn-secondary,
.user-edit-btn-primary {
  width: fit-content;
  padding: 10px 16px;
  border-radius: 8px;
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  text-transform: capitalize;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.user-edit-btn-secondary {
  background: #ffffff;
  border: 1px solid #dddddd;
  color: #202027;
}

.user-edit-btn-primary {
  background: #2b4ff1;
  border: 1px solid #2b4ff1;
  color: #ffffff;
  margin-left: 12px;
}

.sidebar-logo {
  margin-bottom: 2.125rem;
  display: flex;
  justify-content: center;
}
.sidebar-logo a {
  margin-bottom: 0;
}
.sidebar-links {
  display: flex;
  flex-direction: column;
}

.navLink {
  padding: 10px 1.75rem;
  display: flex;
  align-items: center;
  font-size: 0.9375rem;
  line-height: 10px;
  weight: 700;
}

.navLink .navLink_icon {
  color: #757e8c;
  margin-right: 1.25rem;
}

.navLink .navLink_title {
  color: #757e8c;
}

.navLink img,
.sideBar-link img {
  filter: invert(53%) sepia(10%) saturate(526%) hue-rotate(177deg)
    brightness(90%) contrast(86%);
}

.navLink--active img,
.navLink:hover img,
.sideBar-link:hover img {
  filter: none;
}

.navLink--active {
  background-color: #f1f5f8;
  border-radius: 0.3125rem;
}

.navLink--active .navLink_icon {
  color: #2b4ff1;
}

.navLink--active .navLink_title {
  color: #202027;
}

.navLink:hover {
  background-color: #f1f5f8;
  border-radius: 0.3125rem;
}

.navLink:hover .navLink_title {
  color: #202027;
}

.navLink:hover .navLink_icon {
  transition: all 0.5s ease-out;
  color: #202027;
}

.sideBar-link {
  padding: 1.3125rem 1.4375rem;
  display: flex;
  align-items: center;
  font-size: 0.9375rem;
  line-height: 1rem;
}

.sideBar-link .sideBar-link_icon {
  color: #757e8c;
  margin-right: 1.25rem;
}

.sideBar-link .sideBar-link_title {
  color: #757e8c;
}

.sideBar-link {
  cursor: pointer;
}

.sideBar-link:hover .sideBar-link_title {
  color: #202027;
}

.sideBar-link:hover .sideBar-link_icon {
  transition: all 0.5s ease-out;
  color: #2b4ff1;
}

.content-wrapper {
  margin: 2rem auto;
  width: 72%;
}

.user-management-topBlock {
  display: flex;
  align-items: stretch;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.table-header {
  display: flex;
  align-items: center;
}

.table-title {
  padding: 0.875rem 1rem;
  color: #757e8c;
  font-size: 0.9375rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
}

.user-management-title {
  color: #202027;
  font-size: 2.125rem;
  line-height: 2.6875rem;
  letter-spacing: -2%;
}

.user-management-title2 {
  color: #202027;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  letter-spacing: -2%;
}

.user-management-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: -42px;
}

.user-management-links {
  display: flex;
  align-items: center;
}

.user-management-links span {
  font-weight: 450;
  font-size: 0.925rem;
  line-height: 1rem;
  color: #757e8c;
}

.user-management-links .active {
  color: #2b4ff1;
}

.user-management-links span:not(:last-of-type) {
  margin-right: 1rem;
}

.user-management-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  background: #2b4ff1;
  border-radius: 0.5rem;
  color: #fff;
  outline: none;
  cursor: pointer;
  border: none;
}

.check {
  height: 1.25rem;
  width: 1.25rem;
}

.user-name-width {
  width: 30%;
}

.user-status-width {
  width: 10%;
}

.user-email-width {
  width: 22%;
}

.user-tags-width {
  width: 32%;
}

.user-edit-width {
  width: 6%;
}

@media screen and (max-width: 1440px) {
  .content-wrapper {
    margin: 2rem auto;
    width: 86%;
  }
  .user-name-width {
    width: 26%;
  }

  .user-status-width {
    width: 10%;
  }

  .user-email-width {
    width: 28%;
  }

  .user-tags-width {
    width: 20%;
  }

  .user-edit-width {
    width: 6%;
  }
}

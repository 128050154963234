@tailwind base;
@tailwind components;
@tailwind utilities;

@import "common.css";
@import "layout.css";
@import "settings.css";
@import "user-management.css";
@import "loader.css";

/* CUSTOM CSS */
body {
  background: #f7f8fa;
}

/* GENERAL */
textarea:focus,
input:focus {
  /* outline: 1px solid rgba(0, 0, 255, 0.7); */
  outline: none;
}

input[type="checkbox"] {
  outline: none;
}

input:disabled {
  background-color: #f9f9f9;
}

/* ALERT */
.s-alert-info {
  background-color: rgba(0, 0, 255, 0.7);
}

.s-alert-warning {
  background-color: rgba(220, 38, 38, 0.7);
}

/* TIMEZONE PICKER */
.css-1s2u09g-control {
  border-radius: 8px !important;
  border: 1px solid #dddddd !important;
}

.css-1s2u09g-control:focus {
  outline: 1px solid rgba(0, 0, 255, 0.7);
  border: none;
}

.css-1pahdxg-control:hover {
  border: none;
}

/* PHONE */
.react-tel-input .form-control,
.react-tel-input .flag-dropdown {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.react-tel-input .flag-dropdown.open {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
